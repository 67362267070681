<template>
    <div class="clickable-part-number">
        <div class="clickable-part-number__block">
            <a @click="handleGoToDetails">{{ params.value }}</a>
        </div>

        <div
            class="clickable-part-number__thumbnail-img-wrapper clickable-part-number__block"
            v-if="isShowImgs"
        >
            <img
                class="clickable-part-number__img-for-mobile-view"
                :src="thumbnailImgSrc"
                :alt="`${params.value} img`"
            />

            <driv-popover offset="0, 20px" placement="right" popperClass="primary-img-popper">
                <template slot="popper-content"
                    ><div class="popper__primary-img-wrapper">
                        <img :src="primaryImgSrc" :alt="`${params.value} img`" /></div
                ></template>
                <template slot="popper-trigger"
                    ><img :src="thumbnailImgSrc" :alt="`${params.value} img`"
                /></template>
            </driv-popover>
        </div>
    </div>
</template>

<script>
import { createUrlToGoToSearchResults } from "../../../../common/partFinderCorporate.helpers";
import { BRANDS } from "../constants";

export default Vue.extend({
    methods: {
        handleGoToDetails() {
            const { data } = this.params;

            if (!data.detailsPath) return;

            return Vue.Global.Analytics.trackEvent(
                "Part Search Results Link",
                "Part",
                data.partNumber,
                null,
                {
                    callback: function () {
                        if (window.brand === "commercial-parts") {
                            let url = window.location.origin;
                            url = url.replace("drivheavydutyparts", "drivparts");
                            window.open(
                                `${url}/part-details.html?part_number=${data.partNumber}&brand_code=${data.brand}`,
                                "_blank",
                            );
                        } else {
                            window.location.assign(
                                createUrlToGoToSearchResults({
                                    queryObj: {
                                        part_number: data.partNumber,
                                        brand_code: data.brand,
                                    },
                                    path: data.detailsPath,
                                }),
                            );
                        }
                    },
                },
            );
        },
    },
    computed: {
        data() {
            return this.params.data;
        },
        thumbnailImgSrc() {
            return this.data?.imgAssets?.productThumbnails?.[0]?.url;
        },
        primaryImgSrc() {
            return this.data?.imgAssets?.productPrimaries?.[0]?.url;
        },
        isShowImgs() {
            return (
                (this.params.brand === BRANDS.WALKER_EXHAUST ||
                    this.params.brand === BRANDS.COMMERCIAL_PARTS) &&
                this.thumbnailImgSrc &&
                this.primaryImgSrc
            );
        },
    },
});
</script>
